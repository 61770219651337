/* Assets.css */

.table_div {
  max-width: 800px;
  margin: 20px auto;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.ant-table {
  background-color: #fff;
  border-radius: 8px;
}

.ant-table-thead>tr>th {
  background-color: #f8f8f8;
}

.ant-table-tbody>tr>td {
  border-bottom: 1px solid #f0f0f0;
}

.ant-table-tbody>tr:hover>td {
  background-color: #f5f5f5;
}

.ant-spin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}