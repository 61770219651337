/* StockExport.css */

.stockContainer {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 8px;
    transition: background-color 0.3s ease, color 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .date-picker {
    width: 100%;
  }
  
  .download-button {
    margin-top: 10px;
  }
  