/* InfoPage.css */

.container {
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 8px;
    transition: background-color 0.3s ease, color 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .image-container {
    text-align: center;
  }

  .image {
    border-radius: 10px;
  }
  
  .info-section-container {
    display: flex;
    flex-direction: column; /* Stack cards vertically on small screens */
    align-items: center; /* Center cards horizontally */
    width: 100%; /* Adjust the width as needed */
    height: 100%; /* Make the container take 100% height of the parent */
  }
  
  .info-section {
    flex: 1;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    padding: 10px;
    text-align: left;
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 200px; /* Make the cards take 100% height of the parent */
  }
  
  .info-section p {
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  .light-mode {
    background-color: #f8f8f8;
    color: #333;
  }
  
  .dark-mode {
    background-color: #222;
    color: #fff;
  }
  