
.stock-request-list {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.page-title {
    margin-bottom: 20px;
    text-align: center;
}

.add-item-button {
    text-align: center;
    margin-bottom: 20px;
}

.action-buttons {
    display: flex;
    align-items: center;
}

.ant-modal-content {
    border-radius: 8px;
}

.ant-table {
    border-radius: 8px;
    overflow: hidden;
}

.ant-spin {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}
