.dashboard-container {
    padding: 20px;
    /* border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    text-align: center;
  }
  
  .segmented-container {
    margin-bottom: 20px;
    
    
    /* Add styling for your Segmented component */
  }
  
  .statistics-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
   
  }
  
  .custom-card {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .custom-card:hover {
    transform: translateY(-5px);
  }